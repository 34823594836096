import React, { useState, useEffect } from 'react';
import { FaFolder } from 'react-icons/fa';
import { AiFillFilePdf } from 'react-icons/ai';
import './FolderFileManager.css';
import Header from './Header';
import config from './config.json';
import Bot from './bot';

const FolderFileManager = ({
  isModalOpen,
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsModalOpen,
  setIsLoggedIn,
  setUsername,
  setAccessKey,
  remainingCredits,
}) => {
  // State Variables
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderName, setFolderName] = useState('');
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [renameFile, setRenameFile] = useState(null);
  const [newFileName, setNewFileName] = useState('');
  const [renameFolder, setRenameFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [isLoadingFolders, setIsLoadingFolders] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoggedIn, setIsLoggedInState] = useState(true);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [parseStatus, setParseStatus] = useState({});
  const [isParsingComplete, setIsParsingComplete] = useState(true);

  // Retrieve stored user credentials from localStorage
  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  // Check login status on component mount
  useEffect(() => {
    if (!storedEmail || !storedAccessToken) {
      setIsLoggedInState(false);
    } else {
      fetchFolders(storedEmail, storedAccessToken);
    }
  }, [storedEmail, storedAccessToken]);

  // Function to fetch folders
  const fetchFolders = async (email, accessKey) => {
    setIsLoadingFolders(true);
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-folders`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      // Check if the token has expired
      if (response.status === 401) {
        handleLogout();
        return;
      }

      const data = await response.json();
      //console.log('Retrieve Folders Response:', data); // For debugging

      if (response.ok && data.message === "Success" && data.data && Array.isArray(data.data.folders)) {
        const initializedFolders = data.data.folders.map(folder => ({
          ...folder,
          filesFetched: false,
          files: [], // Initialize files as empty array
        }));
        setFolders(initializedFolders);
      } else {
        console.error("Failed to retrieve folders:", data.message);
        triggerPopup(`Failed to retrieve folders: ${data.message}`);
        setFolders([]);
      }

    } catch (error) {
      console.error("Error fetching folders:", error);
      triggerPopup('An error occurred while fetching folders.');
      setFolders([]);
    } finally {
      setIsLoadingFolders(false);
    }
  };

  const fetchParseStatus = async (parseId) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/folder/parse-status?parse_id=${parseId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch parse status');
      }

      const data = await response.json();
      return data.data.folder_parsing_status;
    } catch (error) {
      console.error('Error fetching parse status:', error);
      return null;
    }
  };

  const checkParseStatus = async (folder) => {
    if (folder.parse_id) {
      const status = await fetchParseStatus(folder.parse_id);
      setParseStatus(prev => ({ ...prev, [folder.folder_name]: status }));
      return status === 'completed';
    }
    return true;
  };

  const openFolder = async (folder) => {
    setCurrentFolder(folder);
    const isComplete = await checkParseStatus(folder);
    setIsParsingComplete(isComplete);
    fetchFilesInFolder(folder);
  };


  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsLoggedInState(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.reload();
  }

  const fetchFilesInFolder = async (folder) => {
    if (!folder) return;

    setIsLoadingFiles(true);
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-files-in-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          email: storedEmail,
          folder_name: folder.folder_name,
        }),
      });

      const result = await response.json();
      console.log('Retrieve Files Response:', result);

      if (response.ok && result.message === "Success" && result.data && Array.isArray(result.data.files)) {
        setFolders(prevFolders =>
          prevFolders.map(f => 
            f.folder_name === folder.folder_name 
              ? { ...f, files: result.data.files, filesFetched: true } 
              : f
          )
        );
        setCurrentFolder(prevFolder => ({
          ...prevFolder,
          files: result.data.files,
          filesFetched: true,
        }));
        
        // Check parse status after fetching files
        const isComplete = await checkParseStatus(folder);
        setIsParsingComplete(isComplete);
      } else {
        console.error('Failed to retrieve files:', result.message);
        triggerPopup(`Failed to retrieve files: ${result.message}`);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      triggerPopup('An error occurred while fetching files.');
    } finally {
      setIsLoadingFiles(false);
    }
  };

  // Add new folder
  const handleAddFolder = async () => {
    if (folderName.trim() === '') {
      triggerPopup('Folder name cannot be empty.');
      return;
    }

    const formData = new FormData();
    formData.append("email", storedEmail);
    formData.append("folder_name", folderName);

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/upload-batch`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: formData,
      });

      const result = await response.json();
      //console.log('Add Folder Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        // Add the new folder to the state with 'filesFetched' as false
        setFolders([...folders, { folder_name: folderName, files: [], filesFetched: false }]);
        setFolderName('');
        triggerPopup('Folder added successfully.');
      } else {
        console.error('Failed to add folder:', result.message);
        triggerPopup(`Failed to add folder: ${result.message}`);
      }
    } catch (error) {
      console.error('Error adding folder:', error);
      triggerPopup('An error occurred while adding the folder.');
    }
  };

  // Delete folder
  const handleDeleteFolder = async (folderName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the folder "${folderName}"? This action cannot be undone.`);
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/delete-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ email: storedEmail, folder_name: folderName }),
      });

      const result = await response.json();
      console.log('Delete Folder Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        // Remove the deleted folder from the state
        setFolders(folders.filter((folder) => folder.folder_name !== folderName));
        // If the deleted folder is currently open, close it
        if (currentFolder && currentFolder.folder_name === folderName) {
          setCurrentFolder(null);
        }
        triggerPopup('Folder deleted successfully.');
      } else {
        console.error('Failed to delete folder:', result.message);
        triggerPopup(`Failed to delete folder: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
      triggerPopup('An error occurred while deleting the folder.');
    }
  };

  // Rename folder
  const handleRenameFolder = async () => {
    if (newFolderName.trim() === '') {
      triggerPopup('New folder name cannot be empty.');
      return;
    }

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/rename-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          email: storedEmail,
          old_name: renameFolder,
          new_name: newFolderName,
        }),
      });

      const result = await response.json();
      //console.log('Rename Folder Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        // Update the folder name in the state
        setFolders(
          folders.map((folder) =>
            folder.folder_name === renameFolder ? { ...folder, folder_name: newFolderName } : folder
          )
        );
        // If the renamed folder is currently open, update its name
        if (currentFolder && currentFolder.folder_name === renameFolder) {
          setCurrentFolder({ ...currentFolder, folder_name: newFolderName });
        }
        setRenameFolder(null);
        setNewFolderName('');
        triggerPopup('Folder renamed successfully.');
      } else {
        console.error('Failed to rename folder:', result.message);
        triggerPopup(`Failed to rename folder: ${result.message}`);
      }
    } catch (error) {
      console.error('Error renaming folder:', error);
      triggerPopup('An error occurred while renaming the folder.');
    }
  };

  // Upload PDF files to the current folder (bulk)
  const handleUploadFiles = async () => {
    if (filesToUpload.length === 0 || !currentFolder) {
      triggerPopup('No files selected or no folder open.');
      return;
    }

    const newFiles = Array.from(filesToUpload).map((file) => file.name.toLowerCase());
    if (newFiles.some((fileName) => !fileName.endsWith('.pdf'))) {
      triggerPopup('Only PDF files are allowed.');
      return;
    }

    // Check how many files are currently in the folder
  const currentFileCount = currentFolder.files.length;

  // Calculate the total number of files after the upload
  const totalFilesAfterUpload = currentFileCount + filesToUpload.length;

  // Check if the total exceeds 250
  if (totalFilesAfterUpload > 250) {
    triggerPopup('You cannot upload more than 250 files in a single folder.');
    return;
  }

    const formData = new FormData();
    Array.from(filesToUpload).forEach((file) => {
      formData.append('files', file);
    });
    formData.append('email', storedEmail);
    formData.append('folder_name', currentFolder.folder_name);

    setIsUploading(true);
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/upload-additional-to-folder`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: formData,
      });

      const result = await response.json();
      console.log('Upload Files Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        triggerPopup('Files uploaded successfully. Parsing in progress...');
        setFilesToUpload([]);
        setIsParsingComplete(false);
  
        // Start checking parse status
        const checkStatus = async () => {
          const status = await fetchParseStatus(result.parse_id);
          if (status === 'completed') {
            setIsParsingComplete(true);
            triggerPopup('Parsing completed.');
            // Refresh folder contents
            fetchFilesInFolder(currentFolder);
          } else {
            setTimeout(checkStatus, 5000); // Check again after 5 seconds
          }
        };
        checkStatus();

        // Update the folder's files in state without refetching
        setFolders(prevFolders =>
          prevFolders.map(folder =>
            folder.folder_name === currentFolder.folder_name
              ? { 
                  ...folder, 
                  files: [...folder.files, ...Array.from(filesToUpload).map(file => file.name)], 
                  filesFetched: true 
                }
              : folder
          )
        );

        // Update currentFolder's files
        setCurrentFolder(prevFolder => ({
          ...prevFolder,
          files: [...prevFolder.files, ...Array.from(filesToUpload).map(file => file.name)],
        }));
      } else {
        console.error('Failed to upload files:', result.message);
        triggerPopup(`Failed to upload files: ${result.message}`);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      triggerPopup('An error occurred while uploading files.');
    } finally {
      setIsUploading(false);
    }
  };

  // Delete file
  const handleDeleteFile = async (fileName) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the file "${fileName}"? This action cannot be undone.`);
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/delete-file-in-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          email: storedEmail,
          folder_name: currentFolder.folder_name,
          file_name: fileName,
        }),
      });

      const result = await response.json();
      //console.log('Delete File Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        triggerPopup('File deleted successfully.');
        // Update the folder's files in state without refetching
        setFolders(prevFolders =>
          prevFolders.map(folder =>
            folder.folder_name === currentFolder.folder_name
              ? { 
                  ...folder, 
                  files: folder.files.filter(file => file !== fileName), 
                  filesFetched: true 
                }
              : folder
          )
        );

        // Update currentFolder's files
        setCurrentFolder(prevFolder => ({
          ...prevFolder,
          files: prevFolder.files.filter(file => file !== fileName),
        }));
      } else {
        console.error('Failed to delete file:', result.message);
        triggerPopup(`Failed to delete file: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      triggerPopup('An error occurred while deleting the file.');
    }
  };

  // Open rename modal for file
  const openRenameModal = (fileName) => {
    setRenameFile(fileName);
    setNewFileName(fileName);
  };

  // Handle renaming of file
  const handleRenameFile = async () => {
    if (!newFileName.endsWith('.pdf')) {
      triggerPopup('File name must end with .pdf');
      return;
    }

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/rename-file-in-folder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          email: storedEmail,
          folder_name: currentFolder.folder_name,
          old_name: renameFile,
          new_name: newFileName,
        }),
      });

      const result = await response.json();
      //console.log('Rename File Response:', result); // For debugging

      if (response.ok && result.message === "Success") {
        triggerPopup('File renamed successfully.');
        setRenameFile(null);
        setNewFileName('');

        // Update the folder's files in state without refetching
        setFolders(prevFolders =>
          prevFolders.map(folder =>
            folder.folder_name === currentFolder.folder_name
              ? { 
                  ...folder, 
                  files: folder.files.map(file => 
                    file === renameFile ? newFileName : file
                  ), 
                  filesFetched: true 
                }
              : folder
          )
        );

        // Update currentFolder's files
        setCurrentFolder(prevFolder => ({
          ...prevFolder,
          files: prevFolder.files.map(file => 
            file === renameFile ? newFileName : file
          ),
        }));
      } else {
        console.error('Failed to rename file:', result.message);
        triggerPopup(`Failed to rename file: ${result.message}`);
      }
    } catch (error) {
      console.error('Error renaming file:', error);
      triggerPopup('An error occurred while renaming the file.');
    }
  };

  // Open rename modal for folder
  const openRenameFolderModal = (folderName) => {
    setRenameFolder(folderName);
    setNewFolderName(folderName);
  };

  // Download specific file in a folder
  const handleDownloadFile = async (fileName) => {
    if (!currentFolder) {
      triggerPopup('No folder selected');
      return;
    }

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/download-resume-in-folder?email=${encodeURIComponent(storedEmail)}&folder_name=${encodeURIComponent(currentFolder.folder_name)}&file_name=${encodeURIComponent(fileName)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      triggerPopup('An error occurred while downloading the file.');
    }
  };

  // Download entire folder
  const handleDownloadFolder = async (folderNamee) => {
    const confirmDownload = window.confirm(`Are you sure you want to download the folder "${folderNamee}" as a ZIP file?`);
    if (!confirmDownload) return;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/download-folder?email=${encodeURIComponent(storedEmail)}&folder_name=${encodeURIComponent(folderNamee)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${storedAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${folderNamee}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading folder:', error);
      triggerPopup('An error occurred while downloading the folder.');
    }
  };

  useEffect(() => {
    if (currentFolder && !currentFolder.filesFetched) {
      fetchFilesInFolder(currentFolder);
    }
  }, [currentFolder]);

  // Trigger popup with a message
  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };


  return (
    <div style={{ minHeight: "100vh" }}>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
      />

        {showPopup && (
          <div>
            <div className="overlay" onClick={handleClosePopup}></div>
            <div className="popup" style={{textAlign: "center"}}>
              <button className="close-btn" onClick={handleClosePopup}>×</button>
              {popupMessage}
            </div>
          </div>
        )}

      <div className="folder-file-manager-main">
        {/* Folder List Section */}
        {!storedAccessToken && (
              <p style={{margin: "50px"}}>You are not logged in. Please log in to access your resumes.</p>)}
        {!currentFolder && storedAccessToken && (
          <div className="folder-list-container">
            <h2>Your Folders</h2>
            <div className="folder-input">
              <input
                type="text"
                placeholder="Enter folder name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <button 
                style={{ minWidth: "100px", height: "40px", marginTop: "10px" }} 
                onClick={handleAddFolder}
                disabled={isLoadingFolders}
              >
                {isLoadingFolders ? 'Adding...' : 'Add Folder'}
              </button>
            </div>
            <div className="folders">
            {!storedEmail || !storedAccessToken ? (
              <p >You are not logged in. Please log in to access your resumes.</p>
            ) : folders.length > 0 ? (
              folders.map((folder, index) => (
                <div key={index} className="folder-item">
                  <FaFolder className="folder-icon" />
                  <span
                    onClick={() => openFolder(folder)} // Use openFolder to manage fetching
                    style={{ cursor: 'pointer', marginRight: '10px', width: "400px"}}
                  >
                    {folder.folder_name}
                  </span>
                  <span style={{ color: "#777", fontSize: "0.9rem" }}>
                    {folder.created_date ? new Date(folder.created_date).toLocaleDateString() : "Just Now"}
                  </span>
                  <span style={{color: "#777", fontSize: "0.9rem"}}>{folder.num_files} files</span>
                  <button onClick={() => openRenameFolderModal(folder.folder_name)}>Rename</button>
                  <button onClick={() => handleDownloadFolder(folder.folder_name)}>Download</button>
                  <button onClick={() => handleDeleteFolder(folder.folder_name)}>Delete</button>
                </div>
              ))
            ) : !isLoadingFolders ? (
              <p>No folders available.</p>
            ) : (
              <div className="loader"></div>
            )}
          </div>

          </div>
        )}

        {/* File List Section (Inside Folder) */}
        {currentFolder && (
          <div className="file-manager-container">
            <button 
              style={{ marginBottom: "20px", marginLeft: "-10px" }} 
              onClick={() => setCurrentFolder(null)}
            >
              Back to Folders
            </button>
            <h2 style={{ marginBottom: "20px" }}>{currentFolder.folder_name}</h2>

            {!isParsingComplete && (
            <div className="parsing-message">
              Files are being parsed. Some actions may be temporarily disabled.
            </div>
          )}

          <div className="file-upload">
            <input
              type="file"
              accept=".pdf"
              multiple
              onChange={(e) => setFilesToUpload(e.target.files)}
              disabled={!isParsingComplete}
            />
            <button 
              onClick={handleUploadFiles} 
              disabled={isUploading || !isParsingComplete}
            >
              {isUploading ? 'Uploading...' : 'Upload Files'}
            </button>
          </div>

            <div className="file-list">
              {isLoadingFiles ? (
                <p>Loading files...</p>
              ) : currentFolder.files.length === 0 ? (
                <p>No files in this folder</p>
              ) : (
                currentFolder.files.map((file, index) => (
                  <div key={index} className="file-item">
                    <AiFillFilePdf className="pdf-icon" />
                    <span style={{ marginRight: '10px' }}>{file}</span>
                    <button onClick={() => openRenameModal(file)}>Rename</button>
                    <button onClick={() => handleDownloadFile(file)}>Download</button>
                    <button onClick={() => handleDeleteFile(file)}>Delete</button>
                  </div>
                ))
              )}
            </div>
          </div>
        )}

        {/* Rename File Modal */}
        {renameFile && (
          <div className="rename-modal">
            <div className="folder-modal-content">
              <h2>Rename File</h2>
              <input
                type="text"
                value={newFileName}
                onChange={(e) => setNewFileName(e.target.value)}
                placeholder="Enter new file name"
              />
              <div>
                <button onClick={handleRenameFile}>Save</button>
                <button onClick={() => setRenameFile(null)}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {/* Rename Folder Modal */}
        {renameFolder && (
          <div className="rename-modal">
            <div className="folder-modal-content">
              <h2>Rename Folder</h2>
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="Enter new folder name"
              />
              <div>
                <button onClick={handleRenameFolder}>Save</button>
                <button onClick={() => setRenameFolder(null)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
};

export default FolderFileManager;
