import React, { useEffect, useState } from 'react';
import './ChooseForensics.css';
import { useNavigate } from 'react-router-dom';
import config from './config'; // Adjust this import based on your project structure
import Bot from './bot';

const sleep = ms => new Promise(r => setTimeout(r, ms));

const ChooseForensics = ({
  uploadedFiles,
  isLoggedIn,
  username,
  accessKey,
  remainingCredits,
  setRemainingCredits,
  userId,
  setUploadedFiles,
  jobRole,
  setAccessKey,
  setUsername,
  setIsLoggedIn,
  setFileLength,
  folder,
  driveLink
}) => {
  const [runId, setRunID] = useState(null);
  const [showForensicsModal, setShowForensicsModal] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const navigate = useNavigate();

  const steps = ["Select Job Role", "Upload Resumes", "Choose Validation"];

  // Trigger popup with a message
  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  useEffect(() => {
    if (runId) {
      // Navigate only after runId is successfully set
      navigate(`/results?run_id=${runId}&folder_name=${folder}&job_role=${jobRole.jobTitle}&load=true`);
    }
  }, [runId, navigate, jobRole]);

  // Check user credit status before API request
  const checkCreditStatus = async (totalApiHits) => {
    try {
      const response = await fetch(`${config.AUTH_BASE_URL}/api/credit/check-status`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          product_name: "MAYAMAYA_LITE",
          total_api_hits: totalApiHits,
        }),
      });

      const creditData = await response.json();
      if (creditData.msg && creditData.msg.includes("Bad Authorization header") && !hasLoggedOut) {
        handleLogout();
        setHasLoggedOut(true);
      }
      if (response.ok && creditData.status === 200) {
        setRemainingCredits(creditData.data.credits);
        return creditData.data.credits;
      } else {
        triggerPopup(creditData.message || "Error checking credit status");
        return false;
      }
    } catch (error) {
      console.error("Error checking credit status:", error);
      return false;
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/"; // Redirect to homepage
  };

  const handleForensicsChoice = async (applyForensics) => {
    setShowForensicsModal(false);
    setIsAnimating(true);

    const storedEmail = localStorage.getItem("email");
   
    const numFiles = uploadedFiles.length; // Use uploadedFiles for file count
    setFileLength(numFiles);

    // Check if credits are sufficient based on the number of files
    const availableCredits = await checkCreditStatus(numFiles);

    if (availableCredits < numFiles) {
      triggerPopup("Insufficient credits. Please recharge.");
      return;
    }

    const payload = {
      email: storedEmail,
      job_id: jobRole._id,
      folder_name: folder,
      forensics: true,
      jdm: true,
      user_id: userId
    };


    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/jdm-forensics`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();

        if(data.status == 400 && data.message == "Insufficient credits to perform this operation") {
          triggerPopup("Insufficient credits. Please recharge.");
          await sleep(3000)
          navigate("/profile")
        }
        setRunID(data.data.run_id);
        if (data.data.parsing === false){
            setFileLength(0);
        }
        setRemainingCredits(availableCredits - numFiles);
      } else {
        console.error("Error sending data to the backend.");
      }
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  useEffect(() => {
    handleForensicsChoice(true); // Automatically run this function on component mount
  }, []);

  const currentStep = 3;

  return (
    <div>
      <>
        <div className='stepper-header'><p>COMPLETE YOUR TALENT AQUISITION</p></div>

        <div className="step-progress-bar">
        <p>{currentStep}/3 Job Role</p>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 1 ? 1 : 0) * 33.33}%` }}></div>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 2 ? 1 : 0) * 33.33}%` }}></div>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 3 ? 1 : 0) * 33.33}%` }}></div>
        </div>
      </div>

      </>
      <div className="loader"></div>
        {/* Popup Message */}
        {showPopup && (
          <div>
            <div className="overlay" onClick={() => setShowPopup(false)}></div>
            <div className="popup">
              <button className="close-btn" onClick={() => setShowPopup(false)}>×</button>
              {popupMessage}
            </div>
          </div>
        )}
        <Bot onLoad={handleBotLoad} />
      </div>

  );
};

export default ChooseForensics;
