import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UploadSection from './components/UploadSection';
import Pricing from './components/Pricing';
import Profile from './components/Profile';
import History from './components/History';
import ResetPassword from './components/PasswordReset';
import JobDescription from './components/JobDescriptionPage';
import JobDescription2 from './components/JobDescriptionPage2';
import Results from './components/Results';
import HomePage from './components/HomePage'; // Import the HomePage component
import FolderFileManager from './components/FolderFileManager';
//import FileReview from './components/FileReview';
import ChooseForensics from './components/ChooseForensics';
import './App.css';
import config from './components/config.json'; // Assuming the config file is in components folder

function App() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [driveLink, setDriveLink] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [accessKey, setAccessKey] = useState("");
  const [remainingCredits, setRemainingCredits] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [userId, setUserId] = useState('');
  const [processStatus, setProcessStatus] = useState('');
  const [totalResumes, setTotalResumes] = useState(0);
  const [totalDuplicates, setTotalDuplicates] = useState(0);
  const [historyData, setHistoryData] = useState([]);
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [jobRole, setJobRole] = useState("");
  const [fileLength, setFileLength] = useState(0);
  const [folder, setFolder] = useState("");
  
  

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.href = "/"; // Redirect to homepage
  };

  useEffect(() => {
    const fetchUserCreditsAndSubscription = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const email = localStorage.getItem("email");

        if (!accessToken || !email) return;

        const profileResponse = await fetch(`${config.AUTH_BASE_URL}/api/user/profile`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (profileResponse.status === 401 || profileResponse.status === 403) {
          handleLogout(); // Call handleLogout if token is expired or unauthorized
          return;
        }

        const profileData = await profileResponse.json();

        if (profileData.status === 200) {
          const userId = profileData.data.user_profile.user_id;
          setUserId(userId);

          // Fetch the credits info
          const creditsResponse = await fetch(`${config.AUTH_BASE_URL}/api/user/product`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: userId,
              product_code: 'MAYAMAYA_LITE',
            }),
          });

          const creditsData = await creditsResponse.json();

          if (creditsData.status === 200) {
            const productDetails = creditsData.data.user_product_details;
            setRemainingCredits(productDetails.credits || 0);
            
            setSubscriptionType(productDetails.subscription_type || '');
          } else {
            throw new Error('Failed to fetch credits data');
          }
        } else {
          throw new Error('Failed to fetch profile data');
        }
      } catch (err) {
        console.error('Error fetching user data:', err.message);
      }
    };

    fetchUserCreditsAndSubscription();

  }, [isLoggedIn, accessKey, userId]);

  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/upload" element={
              <UploadSection
                setUploadedFiles={setUploadedFiles}
                setDriveLink={setDriveLink}
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                uploadedFiles={uploadedFiles}
                driveLink={driveLink}
                isLoggedIn={isLoggedIn}
                username={username}
                accessKey={accessKey}
                setRemainingCredits={setRemainingCredits}
                userId={userId}
                totalResumes={totalResumes} // Pass total resumes
                totalDuplicates={totalDuplicates} // Pass total duplicates
                processStatus={processStatus}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
                jobRole={jobRole}
                setFileLength={setFileLength}
                setFolder={setFolder}
              />
            } />
            <Route path="/pricing" element={
              <Pricing
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                subscriptionType={subscriptionType} // Pass subscription type to Pricing page
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/profile" element={
              <Profile
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                accessKey={accessKey}
                remainingCredits={remainingCredits}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/history" element={
              <History
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />
            } />
            <Route path="/results" element={
              <Results
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
                fileLength={fileLength}
              />
            } />
            <Route path="/auth/user/reset-password" element={<ResetPassword />} />
            <Route path="/job-description" element={<JobDescription
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
                setJobRole={setJobRole}
              />} />
              <Route path="/job-description-page" element={<JobDescription2
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
                setJobRole={setJobRole}
              />} />
            {/* New HomePage Route */}
            <Route path="/" element={<HomePage
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />} />
              <Route path="/folder-manager" element={<FolderFileManager
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
                setAccessKey={setAccessKey}
                remainingCredits={remainingCredits}
                setTotalResumes={setTotalResumes}
                setTotalDuplicates={setTotalDuplicates}
                totalResumes={totalResumes}
                totalDuplicates={totalDuplicates}
                historyData={historyData}
                setIsRegisterModalOpen={setIsRegisterModalOpen}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                isRegisterModalOpen={isRegisterModalOpen}
              />} />
              {/*<Route path="/file-review" element={<FileReview
                isLoggedIn={isLoggedIn}
                username={username}
                uploadedFiles={uploadedFiles}
                accessKey={accessKey}
                remainingCredits={remainingCredits}
                setRemainingCredits={setRemainingCredits}
                userId={userId}
                setUploadedFiles={setUploadedFiles}
              />} />*/}
              <Route path="/forensics" element={<ChooseForensics
                isLoggedIn={isLoggedIn}
                username={username}
                uploadedFiles={uploadedFiles}
                accessKey={accessKey}
                remainingCredits={remainingCredits}
                setRemainingCredits={setRemainingCredits}
                userId={userId}
                setUploadedFiles={setUploadedFiles}
                jobRole={jobRole}
                setAccessKey={setAccessKey}
                setUsername={setUsername}
                setIsLoggedIn={setIsLoggedIn}
                setFileLength={setFileLength}
                folder={folder}
                driveLink={driveLink}
              />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
