import React, { useEffect, useState } from 'react';
import Header from './Header';
import './History.css';
import { FaDownload, FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import config from './config.json';
import Bot from './bot';

const History = ({ isModalOpen, isRegisterModalOpen, setIsRegisterModalOpen, setIsModalOpen, setIsLoggedIn, setUsername, setAccessKey, remainingCredits }) => {
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedForensics, setExpandedForensics] = useState({});
  const entriesPerPage = 10;
  const navigate = useNavigate();

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.reload();
  };

  useEffect(() => {
    const fetchHistoryData = async () => {
      const storedEmail = localStorage.getItem('email');
      const storedAccessToken = localStorage.getItem('access_token');

      if (!storedEmail || !storedAccessToken) {
        setError('You are not logged in. Please log in to access your results.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-forensics-jobs-page`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${storedAccessToken}`
          },
          body: JSON.stringify({
            email: storedEmail,
            page_number: 1
          })
        });

        if (response.status === 401) {
          handleLogout(); // If token is expired or unauthorized, log out the user
          return;
        }

        const data = await response.json();

        if (response.ok) {
          setHistoryData(data.data.mayamaya_lite);
          //console.log(data.data.mayamaya_lite);
        } else {
          setError(data.message || 'Failed to fetch data.');
        }
      } catch (error) {
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoryData();
  }, []);

  const handleDownload = async (email, userId) => {
    const accessToken = localStorage.getItem('access_token');

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/resume/forensics/get-result-excel?email=${email}&forensics_id=${userId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'results.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const totalEntries = historyData.length;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);
  const currentEntries = historyData.slice((currentPage - 1) * entriesPerPage, currentPage * entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleForensics = (id) => {
    setExpandedForensics((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDownloadDiscrepancy = async (discrepancyType, forensicsId, email, folderName) => {
    const accessToken = localStorage.getItem('access_token');
  
    try {
      const response = await fetch(
        `${config.DATASCIENCE_API_SUITE}/resume/forensics/retrieve-resumes-by-discrepancy?email=${email}&forensics_id=${forensicsId}&discrepancy=${discrepancyType}&folder_name=${folderName}&mode=lite`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/zip',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to fetch results');
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `resumes_${discrepancyType}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
    } catch (error) {
      console.error('Download failed:', error);
    }
  };
  

  return (
    <div className="historyPage-container">
      <Header 
        isModalOpen={isModalOpen} 
        isRegisterModalOpen={isRegisterModalOpen} 
        setIsRegisterModalOpen={setIsRegisterModalOpen} 
        setIsModalOpen={setIsModalOpen} 
        setIsLoggedIn={setIsLoggedIn} 
        setUsername={setUsername} 
        setAccessKey={setAccessKey} 
        remainingCredits={remainingCredits} 
      />

      <main className="historyPage-main">
        {isLoading ? (
          <div className="loader"></div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : (
          <section className="historyPage-section">
            <h2 className="historyPage-title">Results</h2>
            <div className="historyPage-table-wrapper">
              <table className="historyPage-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Folder Name</th>
                    <th>Number of Files</th>
                    <th>Job Title</th>
                    <th>Forensics Results</th>
                    <th>Job Role Results</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((entry) => (
                    <React.Fragment key={entry.forensics._id}>
                      <tr>
                        <td>{new Date(entry.forensics.created_date).toLocaleDateString()}</td>
                        <td>{entry.forensics.folder_name}</td>
                        <td>{entry.forensics.num_files || 0}</td>
                        <td>{entry.jdm.job_title}</td>
                        <td>
                          <FaChevronDown 
                            style={{ cursor: 'pointer' }} 
                            onClick={() => toggleForensics(entry.forensics._id)} 
                          />
                        </td>
                        <td>
                          <button
                            style={{ border: "none", padding: "10px", cursor: "pointer", borderRadius: "4px" }}
                            onClick={() => navigate(`/results?run_id=${entry.jdm.run_id}&folder_name=${entry.forensics.folder_name}&job_role=${entry.jdm.job_title}&load=false`)}
                          >
                            View Results
                          </button>
                        </td>
                      </tr>
                      {expandedForensics[entry.forensics._id] && (
                        <tr>
                          <td colSpan="6">
                            <table className="forensics-table">
                              <thead>
                                <tr>
                                  <th>Resumes Detected</th>
                                  <th>Good Resumes</th>
                                  <th>Name Discrepancy</th>
                                  <th>Work Experience Discrepancy</th>
                                  <th>Education Discrepancy</th>
                                  <th>Personal Info Discrepancy</th>
                                  <th>Duplicate Groups</th>
                                  <th>Download Results</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{entry.forensics.num_files || 0}</td>
                                  <td>{entry.forensics.clean_resumes || 0}</td>
                                  {['name', 'work_experience', 'education', 'personal_info'].map((discrepancyType) => (
                                    <td key={discrepancyType}>
                                    {entry.forensics.discrepancies?.[discrepancyType]?.value > 0 ? (
                                      <span
                                        className="clickable-discrepancy"
                                        onClick={() => handleDownloadDiscrepancy(discrepancyType, entry.forensics._id, entry.forensics.user_id, entry.forensics.folder_name)}
                                        style={{ cursor: 'pointer', color: '#672024', fontWeight: '700' }}
                                      >
                                        {entry.forensics.discrepancies?.[discrepancyType]?.value}
                                      </span>
                                    ) : (
                                      entry.forensics.discrepancies?.[discrepancyType]?.value || 0
                                    )}
                                  </td>
                                  ))}
                                  <td>{entry.forensics.num_duplicate_groups || 0}</td>
                                  <td>
                                    <FaDownload 
                                      className="download-icon" 
                                      style={{ cursor: 'pointer', fontSize: '1rem', color: '#672024' }} 
                                      onClick={() => handleDownload(entry.jdm.user_name, entry.forensics._id)} 
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </section>
        )}
      </main>

      <footer className="footer">
        <p>MayaMaya Suite of Products - MayaMaya Lite</p>
      </footer>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
};

export default History;
