import React, { useState, useEffect } from 'react';
import './JobDescriptionPage.css';
import Header from './Header';
import { Link, useNavigate } from "react-router-dom";
import config from './config.json';
import logo from './logo.svg';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Bot from './bot';

const JobDescriptionPage = ({
  isModalOpen,
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsModalOpen,
  setIsLoggedIn,
  setUsername,
  setAccessKey,
  remainingCredits,
  setJobRole
}) => {
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [skillInput, setSkillInput] = useState('');
  const [degree, setDegree] = useState('');
  const [major, setMajor] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [jobResponsibilities, setJobResponsibilities] = useState('');
  const [certificates, setCertificates] = useState('');
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const [savedJobDescriptions, setSavedJobDescriptions] = useState([]);
  const [activeTab, setActiveTab] = useState('select');
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [created, setCreated] = useState(false);


  const steps = ["Select Job Role", "Upload Resumes", "Choose Validation"]

  const navigate = useNavigate();

  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  // Fetch saved job descriptions when the component mounts
  useEffect(() => {

      // Fetch email and access token from local storage
  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

    if (storedEmail && storedAccessToken) {
      fetchJobRoles(storedEmail, storedAccessToken);
    }
  }, [storedAccessToken, storedEmail]);

  const fetchJobRoles = async (storedEmail, storedAccessToken) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/fetch-job-roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ UserID: storedEmail }),
      });
  
      const result = await response.json();
      if (response.status === 401 && result.message === "Token expired") {
        // If token is expired, log the user out
        handleLogout();
      } else if (result.status === 200) {
        // Map the result.data to extract job titles
        const jobTitles = result.data.map((job) => ({
          jobTitle: job.job_title,
          paragraph: job.job_description,  // Empty or placeholder for future description
          _id: job._id
        }));
        setSavedJobDescriptions(jobTitles);
        //console.log(jobTitles);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error fetching job roles:', error);
    }
  };

  // Trigger popup with a message
  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };
  
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); // Clear username on logout
    setAccessKey(""); // Clear access key on logout
    localStorage.removeItem("access_token"); // Remove access token from localStorage
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
    localStorage.removeItem("driveLink");
    window.location.reload();
  }


  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleSave = async () => {
    if (!jobTitle || !degree || !major || !workExperience || !jobResponsibilities) {
      triggerPopup('All fields are required!');
      return;
    }
    
    const formattedSkills = requiredSkills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const jobDescriptionParagraph = `${degree} in ${major}, with ${workExperience} of experience. Key responsibilities include ${jobResponsibilities}. Required skills: ${formattedSkills}. ${certificates ? `Certificates: ${certificates}.` : ''}`;
  
    const jobDescription = {
      jobTitle,
      paragraph: jobDescriptionParagraph,
    };
  
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/parse-job-role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          UserID: storedEmail,
          Job: {
            job_title: jobTitle,
            job_description: jobDescriptionParagraph,
          },
        }),
      });
  
      const result = await response.json();
      if (result.status === 200) {
        // Update saved job descriptions state
        setSavedJobDescriptions([...savedJobDescriptions, jobDescription]);
        // Clear the form
        setJobTitle('');
        setRequiredSkills([]);
        setDegree('');
        setMajor('');
        setWorkExperience('');
        setJobResponsibilities('');
        setCertificates('');
        //console.log('Job Description Saved:', jobDescription);
  
        // Call handleOptionsClick after saving
        setCreated(true);

        handleOptionsClick(); // This will proceed to the next step
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error saving job description:', error);
    }
  };

  useEffect(() => {

  if (created) {
    handleOptionsClick();
  }
}, [created]);
  

  const handleSkillInputKeyDown = (e) => {
    if (e.key === 'Enter') handleAddSkill();
  };

  const handleAddSkill = () => {
    if (skillInput && !requiredSkills.includes(skillInput)) {
      setRequiredSkills([...requiredSkills, skillInput]);
      setSkillInput(''); // Clear input after adding skill
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    setRequiredSkills(requiredSkills.filter(skill => skill !== skillToDelete));
  };

  const handleJobDescriptionSelect = (e) => {
    const selectedJobTitle = e.target.value;
    const selectedJob = savedJobDescriptions.find(job => job.jobTitle === selectedJobTitle) || null;
    setSelectedJobDescription(selectedJob);
    setJobRole(selectedJob); // Pass the entire job object including _id
  };
  
  const handleOptionsClick = () => {
    if (selectedJobDescription || created) {
      // Set the selected job role (including _id)
      setJobRole(selectedJobDescription);
      
      // Navigate to the next page
      window.scrollTo(0, 0);
      navigate("/upload");
    } else {
      triggerPopup("Please select a job description before proceeding.");
    }
  };

  const handleLogo = () => {
    navigate("/");
  }  

  const handleDeleteJobDescription = (jobTitleToDelete) => {
    setSavedJobDescriptions(savedJobDescriptions.filter(job => job.jobTitle !== jobTitleToDelete));
    if (selectedJobDescription === jobTitleToDelete) {
      setSelectedJobDescription('');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const handleCancelClick = () => {
    navigate("/");
  }

  

  if (!storedAccessToken) {
    return (
      <div>
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setUsername={setUsername}
          setAccessKey={setAccessKey}
          remainingCredits={remainingCredits}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isRegisterModalOpen={isRegisterModalOpen}
        />
        <div className="job-description-main">
          <p>You are not logged in. Please log in to proceed.</p>
        </div>
        <footer className="footer">
        <p>MayaMaya Lite - A MayaMaya Product</p>
      </footer>
      </div>
    );
  }

  const currentStep = 1;

  return (
    <div style={{minHeight: "calc(100vh - 60px)"}}>
      <div className='stepper-header' ><p>COMPLETE YOUR TALENT AQUISITION</p></div>

      <div className="step-progress-bar">
        <p>{currentStep}/3 Job Role</p>
        <div className="progress-line" style={{margin: '10px 0'}}>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 1 ? 1 : 0) * 33.33}%` }}></div>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 2 ? 1 : 0) * 33.33}%` }}></div>
          <div className="step-progress-fill" style={{ width: `${(currentStep >= 3 ? 1 : 0) * 33.33}%` }}></div>
        </div>
      </div>


      {showPopup && (
          <div>
            <div className="overlay" onClick={handleClosePopup}></div>
            <div className="popup" style={{textAlign: "center"}}>
              <button className="close-btn" onClick={handleClosePopup}>×</button>
              {popupMessage}
            </div>
          </div>
        )}

      <div className="job-description-main">
        <div className="tabs-container">
          <button
            className={activeTab === 'select' ? 'active-tab' : ''}
            onClick={() => setActiveTab('select')}
          >
            Select Job Role
          </button>
          <button
            className={activeTab === 'create' ? 'active-tab' : ''}
            onClick={() => setActiveTab('create')}
          >
            Create Job Role
          </button>
        </div>

        {activeTab === 'select' && (
          <div style={{width: "100%"}}>
          <div className="job-description-container">
            <h2  style={{marginBottom: "30px"}}>1. Select a <span style={{color: "#672024"}}>Job Role</span></h2>
            <select
              id="job-description"
              className="options-popup-dropdown"
              value={selectedJobDescription ? selectedJobDescription.jobTitle : ''}
              onChange={handleJobDescriptionSelect}
              style={{ marginBottom: "20px" }}
            >
              <option value="">-- Select a Job Role --</option>
              {savedJobDescriptions.map((job, index) => (
                <option key={index} value={job.jobTitle}>
                  {job.jobTitle}
                </option>
              ))}
            </select>

            <p className="create-role-text">
              If your desired job role is not listed, you can create a new one. 
            </p>
            <div className="button-container" style={{marginTop: "20px"}}>
              <button className="create-role-btn" onClick={() => setActiveTab('create')}>
                Create Job Role
              </button>
            </div>
          </div>
                      <div className="jd-button-container" style={{marginTop: "20px"}}>
                      <button className="jr-cancel-button" onClick={handleCancelClick}>
                        Cancel
                      </button>
                      <button className="proceed-button" onClick={handleOptionsClick}>
                        Proceed
                      </button>
                    </div>
        </div>
        )}

        {activeTab === 'create' && (
          <>
          <div className="job-description-container2">
            
            <div className="job-form2">
            <div className="form-column">
              <label>Job Title:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Enter job title"
                required
              />

              <label>Required Skills:<span style={{color: "red"}}>*</span></label>
              <div className="skills-container">
                <input
                  type="text"
                  value={skillInput}
                  onChange={(e) => setSkillInput(e.target.value)}
                  onKeyDown={handleSkillInputKeyDown}
                  placeholder="Enter a skill and press Enter to add"
                />
                <button className="add-skill-btn" onClick={handleAddSkill}>Add Skill</button>
                <div className="skills-box">
                  {requiredSkills.map((skill, index) => (
                    <div key={index} className="skill-box">
                      {capitalizeFirstLetter(skill)} 
                      <button className="delete-skill-btn" onClick={() => handleDeleteSkill(skill)}>x</button>
                    </div>
                  ))}
                </div>
              </div>

              <label>Degree:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                placeholder="e.g., Bachelors, Masters"
                required
              />

              <label>Major:<span style={{color: "red"}}>*</span></label>
              <input
                type="text"
                value={major}
                onChange={(e) => setMajor(e.target.value)}
                placeholder="e.g., Computer Science, Engineering"
                required
              />
              </div>

              <div className="form-column">
              <label>Work Experience:<span style={{color: "red"}}>*</span></label>
              <select value={workExperience} onChange={(e) => setWorkExperience(e.target.value)} className='options-popup-dropdown' required>
                <option value="">Select Experience</option>
                <option value="1-3 years">0-1 years</option>
                <option value="1-3 years">1-3 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5-10 years">5-10 years</option>
                <option value="10+ years">10+ years</option>
              </select>

              <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
              <textarea
                value={jobResponsibilities}
                onChange={(e) => setJobResponsibilities(e.target.value)}
                placeholder="Enter key job responsibilities"
                required
              />

              <label>Certificates (if any):</label>
              <input
                type="text"
                value={certificates}
                onChange={(e) => setCertificates(e.target.value)}
                placeholder="Enter any relevant certificates"
              />
              </div>
              <div className="jd-button-container2" style={{paddingBottom: "30px"}}>
          <button className="jr-cancel-button" onClick={handleCancelClick}>
                        Cancel
                      </button>
          <button className="proceed-button" onClick={handleSave}>Save and Proceed</button>
         </div>
              
            </div>
          </div>
         
         </>
        )}
      </div>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
};

export default JobDescriptionPage;
