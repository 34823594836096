import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Header from './Header';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import './Results.css';
import { utils, writeFile } from 'xlsx';
import { FiChevronDown, FiChevronUp, FiGrid, FiList, FiDownload } from 'react-icons/fi';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Bot from './bot';
import config from './config.json';


const ResultsPage = ({ setIsLoggedIn, setUsername, setAccessKey, remainingCredits, fileLength }) => {
    const [viewType, setViewType] = useState('cards');
    const [sortOption, setSortOption] = useState(''); 
    const [forensicFilter, setForensicFilter] = useState('all');
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [shortlisted, setShortlisted] = useState({});
    const [progress, setProgress] = useState(0);
    const [shortlistFilter, setShortlistFilter] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [preShortlisted, setPreShortlisted] = useState([])
    const [popupMessage, setPopupMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showBulkOptions, setShowBulkOptions] = useState(false);

    const [skillsFilter, setSkillsFilter] = useState(false);
    const [experienceFilter, setExperienceFilter] = useState('');
    const [educationFilter, setEducationFilter] = useState('');
    const [totalScoreFilter, setTotalScoreFilter] = useState('');
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [loaded, setLoaded] = useState('true');

const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
};

const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };


    const useQuery = () => {
        return new URLSearchParams(useLocation().search); 
    };


    const query = useQuery();
    const run_id = query.get("run_id"); // Get run_id from URL
    const folder_name = query.get("folder_name"); // Get folder_name from URL
    const jobDescription = query.get("job_role");
    const load = query.get("load");

    useEffect(() => {
        if (load === 'false') {
          setLoaded('false');
        }
      }, [load]);
    

    useEffect(() => {
        let timer;
        if (loading && progress < 90) {
            timer = setInterval(() => {
                setProgress((prevProgress) => Math.min(prevProgress + (90 / (3 * fileLength)), 90));
            }, 1000);
        } else if (progress >= 90 && !isDataFetched) {
            fetchDataWithRetry();
        }
    
        return () => clearInterval(timer);
    }, [loading, progress, fileLength, isDataFetched]);

    const fetchDataWithRetry = async () => {
        const retryInterval = setInterval(async () => {
            try {
                const result = await fetchData();
                if (result && result.length > 0) {
                    clearInterval(retryInterval);
                    setProgress(100);
                    setIsDataFetched(true);
                    setLoading(false);
                    setLoaded('done')
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }, 5000);
    };

    const fetchData = async () => {
        const storedEmail = localStorage.getItem("email");
        const accessToken = localStorage.getItem("access_token");

        if (!storedEmail || !accessToken) {
            setError(new Error("You are not logged in. Please log in to access results."));
            setLoading(false);
            return null;
        }

        try {
            const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/retrieve-jdm-result`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: storedEmail, folder_name, run_id }),
            });

            if (response.status === 401) {
                handleLogout();
                return null;
            }

            const result = await response.json();
            const jdmData = result?.data?.jdm;

            if (jdmData && jdmData.length > 0) {
                setApiData(jdmData);
                setShortlisted({}); // Initialize all checkboxes as unchecked
                return jdmData;
            }
            return null;
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
            setLoading(false);
            setLoaded('done')
            return null;
        }
    };

// Trigger popup with a message
const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const downloadSelected = async () => {
    const selectedIds = Object.keys(shortlisted).filter(id => shortlisted[id]);
    if (selectedIds.length === 0) {
        triggerPopup('No resumes selected for download.');
        return;
    }

    const storedEmail = localStorage.getItem("email");
    const accessToken = localStorage.getItem("access_token");

    if (!storedEmail || !accessToken) {
        triggerPopup('You are not logged in. Please log in to download resumes.');
        return;
    }

    const fileNames = selectedIds.map(id => {
        const item = apiData.find(item => item._id === id);
        return item ? item.file_name : null;
    }).filter(Boolean);

    const queryParams = new URLSearchParams({
        email: storedEmail,
        folder_name: folder_name,
    });

    fileNames.forEach(fileName => {
        queryParams.append('file_names', fileName);
    });

    const url = `${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/download-resumes-from-folder?${queryParams.toString()}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'selected_resumes.zip';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            triggerPopup('Failed to download resumes. Please try again.');
        }
    } catch (error) {
        console.error('Error downloading resumes:', error);
        triggerPopup('An error occurred while downloading resumes.');
    }
};

const downloadResume = async (id) => {
    const storedEmail = localStorage.getItem("email");
    const accessToken = localStorage.getItem("access_token");

    if (!storedEmail || !accessToken) {
        triggerPopup('You are not logged in. Please log in to download resumes.');
        return;
    }

    const item = apiData.find(item => item._id === id);
    if (!item) {
        triggerPopup('Resume not found.');
        return;
    }

    const fileName = item.file_name;

    const url = `${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/download-resume-in-folder?email=${storedEmail}&folder_name=${folder_name}&file_name=${fileName}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            triggerPopup('Failed to download resume. Please try again.');
        }
    } catch (error) {
        console.error('Error downloading resume:', error);
        triggerPopup('An error occurred while downloading the resume.');
    }
};

const handleShortlistChange = (id) => {
    setShortlisted(prev => {
        const updated = { ...prev, [id]: !prev[id] };
        const anySelected = Object.values(updated).some(Boolean);
        setShowBulkOptions(anySelected);
        return updated;
    });
};

const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedShortlisted = {};
    currentData.forEach(item => {
        updatedShortlisted[item._id] = newSelectAll;
    });
    setShortlisted(updatedShortlisted);
    setShowBulkOptions(newSelectAll);
};

const calculateOverallFit = (scores) => {
    if (!scores || !scores['Final Scores']) return 0;
    const finalScores = scores['Final Scores'];
    return (finalScores.total_score)
};

const sortedData = [...apiData].sort((a, b) => {
    const scoreA = calculateOverallFit(a.scores);
    const scoreB = calculateOverallFit(b.scores);
    if (sortOption === 'skills-high') return scoreB - scoreA;
    if (sortOption === 'skills-low') return scoreA - scoreB;
    return 0;
});


// Place this above the pagination logic for currentData
const filteredData = sortedData.filter((item) => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return Object.values(item).some(value => 
      typeof value === 'string' && searchRegex.test(value)
    );
  }).filter((item) => {
    if (shortlistFilter && !item.shortlisted) return false;
    if (forensicFilter === 'good' && item.forensics_result === "Duplicate") return false;
    if (skillsFilter === 'no-gap' && item.gap?.['Technical Skills']?.Gap?.length > 0) return false;
    if (experienceFilter && item.gap?.['Job Experience']?.Gap > experienceFilter) return false;
    if (educationFilter === 'no-gap' && item.gap?.['Education: Degree']?.Gap?.length > 0) return false;
    if (totalScoreFilter && calculateOverallFit(item.scores) < totalScoreFilter) return false;
    return true;
  });
  

const currentData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredData.slice(indexOfFirstItem, indexOfLastItem);
}, [filteredData, currentPage]);

const totalPages = Math.ceil(filteredData.length / itemsPerPage);

useEffect(() => {
    // Reset shortlisted state when currentData changes
    const updatedShortlisted = {};
    currentData.forEach(item => {
        updatedShortlisted[item._id] = shortlisted[item._id] || false;
    });
    setShortlisted(updatedShortlisted);
}, [currentData]);


    const shortlistSelected = async () => {
        const selectedIds = Object.keys(shortlisted).filter(id => shortlisted[id]);
        if (selectedIds.length === 0) {
          triggerPopup('No resumes selected for shortlisting.');
          return;
        }
    
        const accessToken = localStorage.getItem("access_token");
        
        try {
          const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/shortlist-resumes`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',  
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ run_id, folder_name, short_listed_ids: selectedIds })
          });
    
          if (!response.ok) throw new Error('Failed to shortlist resumes.');
          
          const result = await response.json();
          
          // Update the apiData to reflect the new shortlist status
          setApiData(prevData => prevData.map(item => 
            selectedIds.includes(item._id) ? { ...item, shortlisted: true } : item
          ));
    
          triggerPopup('Selected resumes shortlisted successfully!');
        } catch (error) {
          console.error(error);
          triggerPopup('An error occurred while shortlisting resumes.');
        }
      };
    
      const removeFromShortlist = async () => {
        const selectedIds = Object.keys(shortlisted).filter(id => shortlisted[id]);
        if (selectedIds.length === 0) {
          triggerPopup('No resumes selected for removal from shortlist.');
          return;
        }
    
        const accessToken = localStorage.getItem("access_token");
        
        try {
          const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/mayamaya-lite/unshortlist-resumes`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',  
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({ run_id, folder_name, short_listed_ids: selectedIds })
          });
    
          if (!response.ok) throw new Error('Failed to remove resumes from shortlist.');
          
          const result = await response.json();
          
          // Update the apiData to reflect the new shortlist status
          setApiData(prevData => prevData.map(item => 
            selectedIds.includes(item._id) ? { ...item, shortlisted: false } : item
          ));
    
          triggerPopup('Selected resumes removed from shortlist successfully!');
        } catch (error) {
          console.error(error);
          triggerPopup('An error occurred while removing resumes from shortlist.');
        }
      };
    
    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername(""); 
        setAccessKey(""); 
        localStorage.removeItem("access_token"); // Remove access token from localStorage
        localStorage.removeItem("email");
        localStorage.removeItem("uploadedFiles"); // Remove uploaded files from localStorage
        localStorage.removeItem("driveLink");
        window.location.reload();
    }    
    
    const handleViewChange = (view) => {
        setViewType(view);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
      };
    

// Calculate current data based on pagination
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;



    const handleSkillsFilterChange = (e) => {
        setSkillsFilter(e.target.value);
    };
    
    const handleForensicFilter = (filter) => {
        setForensicFilter(filter);
    };

    const handleExperienceFilterChange = (e) => {
        setExperienceFilter(e.target.value);
    };

    const handleEducationFilterChange = (e) => {
        setEducationFilter(e.target.value);
    };

    const handleTotalScoreFilterChange = (e) => {
        setTotalScoreFilter(e.target.value);
    };

    const downloadExcel = () => {
        const dataToExport = filteredData.map(item => ({
            UserID: item._id,
            Name: item.name,
            JobRequirementMatch: item.scores?.['Final Scores']?.job_requirement_match || 'N/A',
            QualificationsMatch: item.scores?.['Final Scores']?.qualifications_match || 'N/A',
            TotalScore: item.scores?.['Final Scores']?.total_score || 'N/A',
            TechnicalSkillsRequired: item.gap?.['Technical Skills']?.Required?.join(', ') || 'N/A',
            TechnicalSkillsGiven: item.gap?.['Technical Skills']?.Given?.join(', ') || 'N/A',
            TechnicalSkillsGap: item.gap?.['Technical Skills']?.Gap?.join(', ') || 'N/A',
            JobExperienceRequired: item.gap?.['Job Experience']?.Required || 'N/A',
            JobExperienceGiven: item.gap?.['Job Experience']?.Given || 'N/A',
            JobExperienceGap: item.gap?.['Job Experience']?.Gap || 'N/A',
            MajorRequired: item.gap?.['Education: Major']?.Required?.join(', ') || 'N/A',
            MajorGiven: item.gap?.['Education: Major']?.Given?.join(', ') || 'N/A',
            MajorGap: item.gap?.['Education: Major']?.Gap?.join(', ') || 'N/A',
            DegreeRequired: item.gap?.['Education: Degree']?.Required?.join(', ') || 'N/A',
            DegreeGiven: item.gap?.['Education: Degree']?.Given?.join(', ') || 'N/A',
            DegreeGap: item.gap?.['Education: Degree']?.Gap?.join(', ') || 'N/A'
        }));
        
        const worksheet = utils.json_to_sheet(dataToExport);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Job Match Results");
        writeFile(workbook, "job_match_results.xlsx");
    };

    const [isDuplicatesRemoved, setIsDuplicatesRemoved] = useState(false); // New state

    const toggleForensicFilter = () => {
        const newFilter = isDuplicatesRemoved ? 'all' : 'good';
        setIsDuplicatesRemoved(!isDuplicatesRemoved);
        handleForensicFilter(newFilter);
    };
    
      const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
      };

      if (loading && loaded==='true') {
        return (
            <>
                <Header setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setAccessKey={setAccessKey} remainingCredits={remainingCredits} />
                <div style={{ padding: '20px', display: "flex", flexDirection: "column", alignItems: "center", height: "100vh" }}>
                    <div style={{ width: 150, height: 150 }}>
                    <svg style={{ height: 0 }}>
                        <defs>
                            <linearGradient id="progressGradient" gradientTransform="rotate(73)">
                                <stop offset="12.05%" stopColor="#030507" />
                                <stop offset="88.83%" stopColor="#113e33" />
                            </linearGradient>
                        </defs>
                    </svg>
                        <CircularProgressbar
                            value={progress}
                            text={`${Math.round(progress)}%`}
                            styles={buildStyles({
                                pathColor: 'url(#progressGradient)',
                                textColor: '#000',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#f8f8f8',
                                textSize: '18px',
                                strokeLinecap: 'square',
                            })}
                            strokeWidth={20}
                            diameter={200}
                        />
                    </div>
                    <p className="progress-text" style={{ marginTop: 20, fontSize: "1rem" }}>Validating & Analyzing Resumes...</p>
                    <p className="progress-text" style={{ marginTop: 10, fontSize: "0.9rem" }}>Our AI system is reviewing the uploaded resumes, assessing key details like work experience, education and required skills. This process will take a moment.</p>
                </div>
            </>
        );
    }

    if (loading && loaded==='false') {
        return (
            <>
            <div className="loader"></div>
            </>
        )}
    

    if (error) {
        return (
            <>
                <Header setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setAccessKey={setAccessKey} remainingCredits={remainingCredits} />
                <div className="results-page-content">{error.message}</div>
            </>
        );
    }

    return (
        <>
            <Header setIsLoggedIn={setIsLoggedIn} setUsername={setUsername} setAccessKey={setAccessKey} remainingCredits={remainingCredits} />
            <div className="results-page-container">
                <main className="results-page-content">
                    <div className="results-page-view-toggle">
                        <h3>Job Role: <span style={{ color: "#672024", fontWeight: "600" }}>{jobDescription}</span></h3>
                        <div style={{display: "flex", flexDirection: "row"}}>


                        <p classname="results-view-button" style={{cursor: "pointer", paddingTop: "10px"}} onClick={() => handleViewChange('cards')}><FiGrid style={{ marginRight: "8px" }} /></p>
                        <p classname="results-view-button" style={{cursor: "pointer", paddingTop: "10px", paddingRight: "20px"}} onClick={() => handleViewChange('table')}><FiList /></p>
                        
                        <button onClick={downloadExcel} className="results-page-download-button"><FiDownload style={{ marginRight: "8px" }} />Download Excel</button>
                        </div>
                    </div>

                    <div className="filter-section-container">
                        <div className={`filter-section ${!isFilterOpen ? 'open' : ''}`}>
                            <div className="filter-row">
                            <select onChange={handleSortChange} className="sort-dropdown">
                                <option value="">Sort by</option>
                                <option value="skills-high">Overall Fit: High to Low</option>
                                <option value="skills-low">Overall Fit: Low to High</option>
                            </select>

                            <button onClick={toggleForensicFilter} style={{padding: "12px 20px"}} className="filter-toggle-button">
                                {isDuplicatesRemoved ? 'Show Duplicates' : 'Remove Duplicates'}
                            </button>
                            <button onClick={() => setShortlistFilter(!shortlistFilter)} style={{padding: "12px 20px"}} className="filter-toggle-button">
                                {shortlistFilter ? 'Show All' : 'Show Shortlisted'}
                            </button>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="search-input-filter"
                                style={{padding: "12px 20px", background: "#fff", border: "1px solid black", borderRadius: "8px"}}
                                />
                            {/*<label>
                                Technical Skills Gap:
                                <select style={{ marginLeft: "8px" }} value={skillsFilter} onChange={handleSkillsFilterChange}>
                                    <option value="">All</option>
                                    <option value="no-gap">No gap</option>
                                </select>
                            </label>                            
                            
                            <label>
                                Job Experience Gap:
                                <select style={{ marginLeft: "8px" }} value={experienceFilter} onChange={handleExperienceFilterChange}>
                                    <option value="">All</option>
                                    <option value="1">1 year</option>
                                    <option value="3">3 years</option>
                                    <option value="5">5 years</option>
                                </select>
                            </label>

                            <label>
                                Education Gap:
                                <select style={{ marginLeft: "8px" }} value={educationFilter} onChange={handleEducationFilterChange}>
                                    <option value="">All</option>
                                    <option value="no-gap">No gap</option>
                                </select>
                            </label>

                            <label>
                                Total Score:
                                <select style={{ marginLeft: "8px" }} value={totalScoreFilter} onChange={handleTotalScoreFilterChange}>
                                    <option value="">All</option>
                                    <option value="60">Above 60</option>
                                    <option value="70">Above 70</option>
                                    <option value="80">Above 80</option>
                                </select>
                            </label>*/}
                            </div>
                        </div>
                    </div>


                    {viewType === 'cards' && (
                        <div className="results-page-cards-container">
                            {currentData.map((item) => (
                                <div key={item._id} className="role-match-card">
                                    <h3>{item.name}</h3>
                                    <div className="progress-bar-container">
                                        <SemiCircleProgressBar
                                            percentage={calculateOverallFit(item.scores)}
                                            showPercentValue
                                            stroke="#672024"
                                            background="#e0e0e0"
                                            strokeWidth={20}
                                            diameter={200}
                                        />
                                    </div>

                                    <div className="gap-section" style={{paddingTop: "10px"}}>
                                        <p className="gap-text"><strong>Technical Skills Gap</strong></p>
                                        <p style={{paddingBottom: "10px", borderBottom: "1px solid #ddd"}} className="gap-text"><span className="red-text" >{item.gap?.['Technical Skills']?.Gap?.join(', ') || 'None'}</span></p>
                                        <p className="gap-text" style={{paddingTop: "10px"}}><strong>Education Gap</strong></p>
                                        <p className="gap-text">Major: <span className="red-text">{item.gap?.['Education: Major']?.Gap?.join(', ') || 'None'}</span></p>
                                        <p style={{paddingBottom: "10px", borderBottom: "1px solid #ddd"}} className="gap-text">Degree: <span className="red-text">{item.gap?.['Education: Degree']?.Gap?.join(', ') || 'None'}</span></p>
                                        <p className="gap-text" style={{paddingTop: "10px"}}>
                                        <strong>Job Experience Gap:</strong>{' '}
                                        {item.gap?.['Job Experience']?.Gap !== undefined ? (
                                            <span className={item.gap['Job Experience'].Gap < 0 ? "green-text" : "red-text"}>
                                            {Math.abs(item.gap['Job Experience'].Gap)} years
                                            </span>
                                        ) : (
                                            <span>None</span>
                                        )}
                                        </p>
                                    </div>

                                    {/*<button onClick={downloadExcel} className="download-resume-button">Download Resume</button>*/}
                                </div>
                            ))}
                        </div>
                    )}

{viewType === 'table' && (
                        <div className="bulk-actions-dropdown">
                            <input 
                                type="checkbox" 
                                checked={currentData.every(item => shortlisted[item._id])}
                                onChange={toggleSelectAll} 
                            />
                            Select All
                            <button 
                                className="bulk-actions-button" 
                                disabled={!Object.values(shortlisted).some(Boolean)}
                                onClick={() => setShowBulkOptions(!showBulkOptions)}
                            >
                                Bulk Options <FiChevronDown />
                            </button>
                            {showBulkOptions && (
                                <div className="bulk-actions-menu">
                                    <button onClick={shortlistSelected}>Shortlist Selected</button>
                                    <button onClick={removeFromShortlist}>Remove from Shortlist</button>
                                    <button onClick={downloadSelected}>Download Selected</button>
                                </div>
                            )}
                        </div>
                    )}

                    {viewType === 'table' && (
                        <div className="table-scrollable-container">
                        <table className="results-page-table">
                            <thead>
                            <tr>
                                <th>
                                
                                </th>
                                <th>#</th>
                                <th>NAME</th>
                                <th>VALIDATION</th>
                                <th>TECH SKILLS GAP</th>
                                <th>JOB EXP GAP</th>
                                <th>MAJOR GAP</th>
                                <th>DEG GAP</th>
                                <th>TOTAL SCORE (%)</th>
                                <th>RESUME</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentData.map((item, index) => {
                                const totalScore = item.scores?.['Final Scores']?.total_score || 0;
                                const matchPercentage = calculateOverallFit(item.scores);
                                const isDuplicate = item.forensics_result == 'Duplicate';
                                const hasGaps = item.gap && Object.keys(item.gap).some(key => item.gap[key]?.Gap?.length > 0);

                                return (
                                    <tr 
                                    key={item._id} 
                                    className={`
                                    ${totalScore > 50 ? 'bluish-row' : ''} 
                                    ${matchPercentage > 70 ? 'greenish-row' : ''} 
                                    ${isDuplicate ? 'yellow-border' : ''}
                                    `}
                                >
                                <td>
                                    <input 
                                    type="checkbox" 
                                    checked={shortlisted[item._id] || false} 
                                    onChange={() => handleShortlistChange(item._id)} 
                                    />
                                </td>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.forensics_result}</td>
                                <td className={`gap-text ${item.gap?.['Technical Skills']?.Gap.length < 0 ? 'black-text' : 'red-downward-triangle'}`}>{item.gap?.['Technical Skills']?.Gap?.join(', ') || 'None'}</td>
                                <td className={`gap-text ${item.gap?.['Job Experience']?.Gap < 0 ? 'green-upward-triangle' : 'red-downward-triangle'}`}>
                                    {Math.abs(item.gap?.['Job Experience']?.Gap || 0)} year(s)
                                </td>

                                <td className={`gap-text ${item.gap?.['Education: Major']?.Gap.length < 0 ? 'black-text' : 'red-downward-triangle'}`}>{item.gap?.['Education: Major']?.Gap?.join(', ') || 'None'} </td>
                                <td className={`gap-text ${item.gap?.['Education: Degree']?.Gap.length < 0 ? 'black-text' : 'red-downward-triangle'}`}>{item.gap?.['Education: Degree']?.Gap?.join(', ') || 'None'} </td>
                                <td>{calculateOverallFit(item.scores)}%</td>
                                <td>
                                    <FiDownload className="download-icon" onClick={() => downloadResume(item._id)} />
                                </td>
                                </tr>
                            )})}
                            </tbody>
                        </table>
                        </div>
                    )}

                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                    {showPopup && (
                    <div>
                        <div className="overlay" onClick={handleClosePopup}></div>
                        <div className="popup" style={{textAlign: "center"}}>
                        <button className="close-btn" onClick={handleClosePopup}>×</button>
                        {popupMessage}
                        </div>
                    </div>
                    )}

                </main>
                
            </div>
            <Bot onLoad={handleBotLoad} />
        </>
    );
};

export default ResultsPage;
