import Header from './Header';
import React, { useState, useEffect } from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import config from './config.json';
import Bot from './bot';

export default function JobDescriptionPage({
  isModalOpen,
  isRegisterModalOpen,
  setIsRegisterModalOpen,
  setIsModalOpen,
  setIsLoggedIn,
  setUsername,
  setAccessKey,
  remainingCredits,
  setJobRole
}) {
  const [jobTitle, setJobTitle] = useState('');
  const [requiredSkills, setRequiredSkills] = useState([]);
  const [skillInput, setSkillInput] = useState('');
  const [degree, setDegree] = useState('');
  const [major, setMajor] = useState('');
  const [workExperience, setWorkExperience] = useState('');
  const [jobResponsibilities, setJobResponsibilities] = useState('');
  const [certificates, setCertificates] = useState('');
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const [savedJobDescriptions, setSavedJobDescriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('select');
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editableJob, setEditableJob] = useState(null);

  const itemsPerPage = 5;
  const storedEmail = localStorage.getItem("email");
  const storedAccessToken = localStorage.getItem("access_token");

  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  useEffect(() => {
    if (storedEmail && storedAccessToken) {
      fetchJobRoles(storedEmail, storedAccessToken);
    }
  }, [storedAccessToken, storedEmail]);

  const fetchJobRoles = async (storedEmail, storedAccessToken) => {
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/fetch-job-roles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({ UserID: storedEmail }),
      });

      const result = await response.json();
      if (response.status === 401 && result.message === "Token expired") {
        handleLogout();
      } else if (result.status === 200) {
        const jobTitles = result.data.map((job) => ({
          jobTitle: job.job_title,
          paragraph: job.job_description,  
          job_id: job._id,
        }));
        setSavedJobDescriptions(jobTitles);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error fetching job roles:', error);
    }
  };

  const openEditPopup = (job) => {
    const parsedJob = parseJobDescription(job.paragraph);
    setEditableJob({ ...job, ...parsedJob });
    setIsEditPopupOpen(true);
  };

  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
    setEditableJob(null);
    setSkillInput('');
  };

  const parseJobDescription = (paragraph) => {
    const degreeMatch = paragraph.match(/(\w+) in (\w+)/);
    const experienceMatch = paragraph.match(/(\d+-\d+|\d+\+) years of experience/);
    const responsibilitiesMatch = paragraph.match(/Key responsibilities include (.*?)\./);
    const skillsMatch = paragraph.match(/Required skills: (.*?)\./);
    const certificatesMatch = paragraph.match(/Certificates: (.*?)\./);

    return {
      degree: degreeMatch ? degreeMatch[1] : '',
      major: degreeMatch ? degreeMatch[2] : '',
      workExperience: experienceMatch ? experienceMatch[1] : '',
      jobResponsibilities: responsibilitiesMatch ? responsibilitiesMatch[1] : '',
      requiredSkills: skillsMatch ? skillsMatch[1].split(', ') : [],
      certificates: certificatesMatch ? certificatesMatch[1] : '',
    };
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername(""); 
    setAccessKey("");
    localStorage.removeItem("access_token"); 
    localStorage.removeItem("email");
    localStorage.removeItem("uploadedFiles");
    localStorage.removeItem("driveLink");
    window.location.reload();
  };

  const triggerPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  const handleSave = async () => {
    if (!jobTitle || !degree || !major || !workExperience || !jobResponsibilities) {
      triggerPopup('All fields are required!');
      return;
    }

    const formattedSkills = requiredSkills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const jobDescriptionParagraph = `${degree} in ${major}, with ${workExperience} of experience. Key responsibilities include ${jobResponsibilities}. Required skills: ${formattedSkills}. ${certificates ? `Certificates: ${certificates}.` : ''}`;

    const jobDescription = { jobTitle, paragraph: jobDescriptionParagraph };

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/parse-job-role`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`,
        },
        body: JSON.stringify({
          UserID: storedEmail,
          Job: { job_title: jobTitle, job_description: jobDescriptionParagraph },
        }),
      });

      const result = await response.json();
      if (result.status === 200) {
        setSavedJobDescriptions([...savedJobDescriptions, jobDescription]);
        setJobTitle('');
        setRequiredSkills([]);  // Clear the requiredSkills
        setDegree('');
        setMajor('');
        setWorkExperience('');
        setJobResponsibilities('');
        setCertificates('');
        setSkillInput('');  // Also clear the skillInput
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error saving job description:', error);
    }
  };

  const handleAddSkillEdit = () => {
    if (skillInput) {
      if (editableJob) {
        if (!editableJob.requiredSkills.includes(skillInput)) {
          setEditableJob({
            ...editableJob,
            requiredSkills: [...editableJob.requiredSkills, skillInput]
          });
        }
      } else {
        if (!requiredSkills.includes(skillInput)) {          
          setRequiredSkills([...requiredSkills, skillInput]);
        }
      }
      setSkillInput('');
    }
  };

  const handleAddSkill = () => {
    if (skillInput && !requiredSkills.includes(skillInput)) {
      setRequiredSkills([...requiredSkills, skillInput]);
      setSkillInput(''); // Clear input after adding skill
    }
  };

  const handleDeleteSkill = (skillToDelete) => {
    if (editableJob) {
      setEditableJob({
        ...editableJob,
        requiredSkills: editableJob.requiredSkills.filter(skill => skill !== skillToDelete)
      });
    } else {
      setRequiredSkills(requiredSkills.filter(skill => skill !== skillToDelete));
    }
  };

  const handleJobDescriptionSelect = (job) => {
    setSelectedJobDescription(selectedJobDescription?.jobTitle === job.jobTitle ? '' : job);
  };

  const handleDelete = async (jobTitle) => {
    const jobToDelete = savedJobDescriptions.find(job => job.job_id === jobTitle);
    if (!jobToDelete) return;
    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/delete-job-role`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${storedAccessToken}` },
        body: JSON.stringify({ job_id: jobToDelete.job_id }),
      });
      const result = await response.json();
      if (response.ok) {
        setSavedJobDescriptions(savedJobDescriptions.filter(job => job.job_id !== jobTitle));
      } else {
        console.error('Failed to delete job:', result.message);
      }
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleEditJobDescription = async () => {
    if (!editableJob) return;

    const formattedSkills = editableJob.requiredSkills.map(skill => capitalizeFirstLetter(skill)).join(', ');
    const newDescription = `${editableJob.degree} in ${editableJob.major}, with ${editableJob.workExperience} of experience. Key responsibilities include ${editableJob.jobResponsibilities}. Required skills: ${formattedSkills}. ${editableJob.certificates ? `Certificates: ${editableJob.certificates}.` : ''}`;

    try {
      const response = await fetch(`${config.DATASCIENCE_API_SUITE}/api/job-description-matcher/edit-job-description`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedAccessToken}`
        },
        body: JSON.stringify({
          job_id: editableJob.job_id,
          new_description: newDescription
        })
      });

      const result = await response.json();
      if (response.ok) {
        setSavedJobDescriptions(savedJobDescriptions.map(job =>
          job.job_id === editableJob.job_id ? { ...job, paragraph: newDescription } : job
        ));
        closeEditPopup();
        triggerPopup('Job description updated successfully');
        setSkillInput('');
        setEditableJob(prev => ({ ...prev, requiredSkills: [] }));
      } else {
        console.error('Failed to edit job description:', result.message);
        triggerPopup('Failed to update job description');
      }
    } catch (error) {
      console.error('Error editing job description:', error);
    }
  };

  const handleSkillInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddSkill();
    }
  };

  const handleSkillInputKeyDownEdit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddSkillEdit();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
  };

  const filteredJobs = savedJobDescriptions.filter(job => 
    job.jobTitle.toLowerCase().includes(searchTerm)
  );

  const indexOfLastJob = currentPage * itemsPerPage;
  const indexOfFirstJob = indexOfLastJob - itemsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  if (!storedAccessToken) {
    return (
      <div>
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setUsername={setUsername}
          setAccessKey={setAccessKey}
          remainingCredits={remainingCredits}
          setIsRegisterModalOpen={setIsRegisterModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          isRegisterModalOpen={isRegisterModalOpen}
        />
        <div className="job-description-main">
          <p>You are not logged in. Please log in to access job roles.</p>
        </div>
        <footer className="footer"><p>MayaMaya Lite - A MayaMaya Product</p></footer>
      </div>
    );
  }

  return (
    <div>
      <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
      />

      {showPopup && (
        <div>
          <div className="overlay" onClick={handleClosePopup}></div>
          <div className="popup" style={{textAlign: "center"}}>
            <button className="close-btn" onClick={handleClosePopup}>×</button>
            {popupMessage}
          </div>
        </div>
      )}

      <div className="job-description-main">
        <div className="tabs-container">
          <button
            className={activeTab === 'select' ? 'active-tab' : ''}
            onClick={() => setActiveTab('select')}
          >
            Job Roles
          </button>
          <button
            className={activeTab === 'create' ? 'active-tab' : ''}
            onClick={() => setActiveTab('create')}
          >
            Create Job Role
          </button>
        </div>
        
        {activeTab === 'select' && (
          <div className="job-description-container">
            <h2>Job Roles</h2>
            <div className='jr-accordion-container'>
              <input
                type="text"
                placeholder="Search job roles..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-bar"
                style={{marginBottom: "50px"}}
              />

              {currentJobs.map((job, index) => (
                <div key={index} className="jr-accordion-item">
                  <button className={`jr-accordion-button ${selectedJobDescription?.job_id === job.job_id ? 'active' : ''}`} onClick={() => handleJobDescriptionSelect(job)}>
                    {job.jobTitle} 
                    <FaEdit className="edit-icon" onClick={(e) => { e.stopPropagation(); openEditPopup(job); }} />
                    <FaTrash className="delete-icon" onClick={() => handleDelete(job.job_id)} />
                  </button>
                  {selectedJobDescription?.jobTitle === job.jobTitle && (
                    <div className={`jr-accordion-content ${selectedJobDescription?.jobTitle === job.jobTitle ? 'show' : ''}`}>
                      <p>{job.paragraph}</p>
                    </div>
                  )}
                </div>
              ))}

              {isEditPopupOpen && editableJob && (
                <div className="edit-popup-overlay" onClick={closeEditPopup}>
                  <div className="edit-popup" onClick={(e) => e.stopPropagation()}>
                    <button className="close-btn" onClick={closeEditPopup}>×</button>
                    <h3>Edit Job Description</h3>
                    <div className="job-form2">
                      <div className="form-column">
                        <label>Job Title:<span style={{color: "red"}}>*</span></label>
                        <input
                          type="text"
                          value={editableJob.jobTitle}
                          onChange={(e) => setEditableJob({...editableJob, jobTitle: e.target.value})}
                          placeholder="Enter job title"
                          required
                        />

                        <label>Required Skills:<span style={{color: "red"}}>*</span></label>
                        <div className="skills-container">
                          <input
                            type="text"
                            value={skillInput}
                            onChange={(e) => setSkillInput(e.target.value)}
                            onKeyDown={handleSkillInputKeyDownEdit}
                            placeholder="Enter a skill and press Enter to add"
                          />
                          <button className="add-skill-btn" onClick={handleAddSkillEdit}>Add Skill</button>
                          <div className="skills-box">
                            {editableJob.requiredSkills.map((skill, index) => (
                              <div key={index} className="skill-box">
                                {capitalizeFirstLetter(skill)} 
                                <button className="delete-skill-btn" onClick={() => setEditableJob({...editableJob, requiredSkills: editableJob.requiredSkills.filter(s => s !== skill)})}>x</button>
                              </div>
                            ))}
                          </div>
                        </div>

                        <label>Degree:<span style={{color: "red"}}>*</span></label>
                        <input
                          type="text"
                          value={editableJob.degree}
                          onChange={(e) => setEditableJob({...editableJob, degree: e.target.value})}
                          placeholder="e.g., Bachelors, Masters"
                          required
                        />

                        <label>Major:<span style={{color: "red"}}>*</span></label>
                        <input
                          type="text"
                          value={editableJob.major}
                          onChange={(e) => setEditableJob({...editableJob, major: e.target.value})}
                          placeholder="e.g., Computer Science, Engineering"
                          required
                        />
                      </div>
                      <div className="form-column">
                        <label>Work Experience:<span style={{color: "red"}}>*</span></label>
                        <select 
                          value={editableJob.workExperience} 
                          onChange={(e) => setEditableJob({...editableJob, workExperience: e.target.value})} 
                          className='options-popup-dropdown' 
                          required
                        >
                          <option value="">Select Experience</option>
                          <option value="0-1 years">0-1 years</option>
                          <option value="1-3 years">1-3 years</option>
                          <option value="3-5 years">3-5 years</option>
                          <option value="5-10 years">5-10 years</option>
                          <option value="10+ years">10+ years</option>
                        </select>

                        <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
                        <textarea
                          value={editableJob.jobResponsibilities}
                          onChange={(e) => setEditableJob({...editableJob, jobResponsibilities: e.target.value})}
                          placeholder="Enter key job responsibilities"
                          required
                        />

                        <label>Certificates (if any):</label>
                        <input
                          type="text"
                          value={editableJob.certificates}
                          onChange={(e) => setEditableJob({...editableJob, certificates: e.target.value})}
                          placeholder="Enter any relevant certificates"
                        />
                      </div>
                    </div>
                    <button className="save-btn" onClick={handleEditJobDescription}>Save Changes</button>
                  </div>
                </div>
              )}

              <div className="pagination">
                {Array.from({ length: Math.ceil(filteredJobs.length / itemsPerPage) }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handlePageChange(i + 1)}
                    className={`pagination-button ${currentPage === i + 1 ? 'active' : ''}`}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {activeTab === 'create' && (
          <div className="job-description-container2">
            <h2>Create a New <span style={{color: "#672024"}}>Job Role</span></h2>
            <div className="job-form2">
              <div className="form-column">
                <label>Job Title:<span style={{color: "red"}}>*</span></label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="Enter job title"
                  required
                />

                <label>Required Skills:<span style={{color: "red"}}>*</span></label>
                <div className="skills-container">
                  <input
                    type="text"
                    value={skillInput}
                    onChange={(e) => setSkillInput(e.target.value)}
                    onKeyDown={handleSkillInputKeyDown}
                    placeholder="Enter a skill and press Enter to add"
                  />
                  <button className="add-skill-btn" onClick={handleAddSkill}>Add Skill</button>
                  <div className="skills-box">
                    {requiredSkills.map((skill, index) => (
                      <div key={index} className="skill-box">
                        {capitalizeFirstLetter(skill)} 
                        <button className="delete-skill-btn" onClick={() => handleDeleteSkill(skill)}>x</button>
                      </div>
                    ))}
                  </div>
                </div>

                <label>Degree:<span style={{color: "red"}}>*</span></label>
                <input
                  type="text"
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  placeholder="e.g., Bachelors, Masters"
                  required
                />

                <label>Major:<span style={{color: "red"}}>*</span></label>
                <input
                  type="text"
                  value={major}
                  onChange={(e) => setMajor(e.target.value)}
                  placeholder="e.g., Computer Science, Engineering"
                  required
                />
              </div>
              <div className="form-column">
                <label>Work Experience:<span style={{color: "red"}}>*</span></label>
                <select value={workExperience} onChange={(e) => setWorkExperience(e.target.value)} className='options-popup-dropdown' required>
                  <option value="">Select Experience</option>
                  <option value="0-1 years">0-1 years</option>
                  <option value="1-3 years">1-3 years</option>
                  <option value="3-5 years">3-5 years</option>
                  <option value="5-10 years">5-10 years</option>
                  <option value="10+ years">10+ years</option>
                </select>

                <label>Job Responsibilities:<span style={{color: "red"}}>*</span></label>
                <textarea
                  value={jobResponsibilities}
                  onChange={(e) => setJobResponsibilities(e.target.value)}
                  placeholder="Enter key job responsibilities"
                  required
                />

                <label>Certificates (if any):</label>
                <input
                  type="text"
                  value={certificates}
                  onChange={(e) => setCertificates(e.target.value)}
                  placeholder="Enter any relevant certificates"
                />
              </div>
            </div>
            <div className="button-container">
              <button className="proceed-button" onClick={handleSave}>Save Job Role</button>
            </div>
          </div>
        )}
      </div>
      <footer className="footer"><p>MayaMaya Suite of Products - MayaMaya Lite</p></footer>
      <Bot onLoad={handleBotLoad} />
    </div>
  );
}