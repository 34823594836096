import React from 'react';
import './HomePage.css';
import { FaArrowRight } from 'react-icons/fa'; // For CTA Button Icon
import Header from './Header';
import { Link, useNavigate } from "react-router-dom";
import img1 from './1.jpg';  // Adjust the path based on where your images are stored
import img2 from './2.jpg';
import img3 from './3.jpg';
import img4 from './4.jpg';
import Bot from './bot';

const HomePage = ({
    isModalOpen,
    isRegisterModalOpen,
    setIsRegisterModalOpen,
    setIsModalOpen,
    setIsLoggedIn,
    setUsername,
    setAccessKey,
    remainingCredits,
  }) => {

    // Updated handleClick logic
    function handleGetStartedClick() {
      const storedEmail = localStorage.getItem("email");
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
          // If user is logged in, navigate to the job description page
          setIsModalOpen(true);
      } else {
          // If user is not logged in, open the modal
          navigate("/job-description");
      }
  }
      const navigate = useNavigate();

  // onLoad function that will be passed to the Bot
  const handleBotLoad = (hubspot) => {
    //console.log("HubSpot chatbot loaded successfully:", hubspot);
    // You can add additional logic to interact with the HubSpot bot here if needed
  };

  return (
    <div className="homepage-unique">
    <Header
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
        setAccessKey={setAccessKey}
        remainingCredits={remainingCredits}
        setIsRegisterModalOpen={setIsRegisterModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        isRegisterModalOpen={isRegisterModalOpen}
      />
      {/* Hero Section */}
      <section className="hero-section-unique">
        <div className="hero-content-unique">
          <h2 className="tagline-unique">Uncover Authentic Talent. Streamline Your Hiring.</h2>
          <h1 className="headline-unique">Revolutionize Your Recruitment with <span style={{color: "#672024"}}>MayaMaya Lite</span></h1>
          <p className="subheading-unique">AI-driven resume analysis and intelligent matching for perfect job fits, ensuring you never miss out on top talent.</p>
          <button className="cta-button-homepage" onClick={handleGetStartedClick}>
            Get Started <FaArrowRight className="cta-icon-unique" />
          </button>
        </div>
      </section>

      {/* Problem Statement */}
<section className="problem-statement-unique">
  <h2 className="section-title-unique">A Smarter Way to Recruit</h2>
  <p className="section-text-unique">
  Profile Validation: Verify candidate profiles for various discrepancies and duplicates.
  </p>
  <p className="section-text-unique">
  Job Matching: Match ideal candidates with your job requirements.
  </p>
  <p className="section-text-unique">
  Insightful Shortlisting: Uncover skills gaps and shortlist top candidates.
  </p>
  <button className="cta-button-homepage" onClick={handleGetStartedClick}>
            Upload Resumes
          </button>
</section>

      {/* How it Works */}
      <section className="how-it-works-unique">
        <h2 className="section-title-unique">How It <span style={{color: "#672024"}}>Works</span></h2>
        <div className="steps-container-unique">
          <div className="step-unique step-left">
            <div className="step-text-unique">
              <h3>Step 1: Create or Select Job Description</h3>
              <p>Tailor a job description that accurately reflects the qualifications and traits you're seeking. You can either create a new job posting or select from your previous templates, ensuring clarity in your hiring process.</p>
            </div>
            <img src={img1} alt="Job Description" className="step-image-unique" />
          </div>
          <div className="step-unique step-right">
            <img src={img2} alt="Upload Resumes" className="step-image-unique" />
            <div className="step-text-unique">
              <h3>Step 2: Upload Resumes</h3>
              <p>Seamlessly upload resumes from your own database or external sources, and let MayaMaya Lite handle the organization and analysis for you.</p>
            </div>
          </div>
          <div className="step-unique step-left">
            <div className="step-text-unique">
              <h3>Step 3: Analyze and Match using AI</h3>
              <p>Our cutting-edge AI analyzes each resume for authenticity, verifying credentials and experiences. Based on your job description, MayaMaya Lite intelligently matches you with top candidates.</p>
            </div>
            <img src={img3} alt="AI Analysis" className="step-image-unique" />
          </div>
          <div className="step-unique step-right">
            <img src={img4} alt="Review and Connect" className="step-image-unique" />
            <div className="step-text-unique">
              <h3>Step 4: Review and Shortlist</h3>
              <p>Once the AI presents the most promising candidates, review their profiles in detail and shortlist those who fit your criteria. Start interviews with confidence and streamline your hiring process.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits for Employers */}
      <section className="benefits-section-unique">
        <h2 className="section-title-unique">Unlock Efficient Hiring</h2>
        <div className="benefits-cards-unique">
          <div className="benefit-card-unique">
            <h3>Save Time</h3>
            <p>Reduce your hiring time with automated resume analysis and intelligent candidate filtering, enabling you to focus on interviews rather than screening.</p>
          </div>
          <div className="benefit-card-unique">
            <h3>Improve Accuracy</h3>
            <p>Verify candidates’ educational and professional background instantly, ensuring that only credible and qualified individuals make it through to your review.</p>
          </div>
          <div className="benefit-card-unique">
            <h3>Evaluate Experience</h3>
            <p>Simplify the application process, making it easier for recruiters yo hire, leading to better recruitment and a positive experience.</p>
          </div>
          <div className="benefit-card-unique">
            <h3>Decide with AI</h3>
            <p>Leverage AI insights on candidate potential and job fit with, helping you make informed decisions without biases.</p>
          </div>
        </div>
      </section>

      {/* Final Call to Action */}
      <section className="final-cta-section-unique">
        <h2>Get Started with MayaMaya Lite Today</h2>
        <p>Discover the ease of AI-driven hiring. Save time, enhance accuracy, and shortlist top talent effortlessly.</p>
        <button className="cta-button-unique" onClick={handleGetStartedClick}>
          Get Started <FaArrowRight className="cta-icon-unique" />
        </button>
      </section>
      
      <footer className="footer">
        <p>MayaMaya Suite of Products - MayaMaya Lite</p>
      </footer>
      <Bot onLoad={handleBotLoad} />
    </div>
    
  );
};

export default HomePage;